import React from 'react'
import { styled, css } from '@stitches/react'
import { vars } from '@seed-design/design-token'
import useDarkModeState from '../../../hooks/useDarkModeState'

import ButtonComponent from './commons/Button'
import PrismicRichTextComponent from './commons/PrismicRichTextComponent'
import DescriptionSection from './commons/DescriptionSection'

import type {
  linkProps,
  SliceItemType,
  PrismicRichTextComponentProps,
} from '../types'

export interface ButtonSectionSliceProps
  extends SliceItemType<'button_section', string | null> {
  items: ButtonSectionSlicePrimaryPropsItemProps[]
  primary: ButtonSectionSlicePrimaryProps
}

type ButtonSectionSlicePrimaryProps = {
  button_section_button_link: linkProps
  button_section_button_text: string | null
  button_section_button_background_color_light: string | null
  button_section_button_background_color_dark: string | null
  button_section_background_color_light: string | null
  button_section_background_color_dark: string | null
  button_section_description: PrismicRichTextComponentProps
}

type ButtonSectionSlicePrimaryPropsItemProps = any

export default function ButtonSectionSlice({
  slice,
}: {
  slice: ButtonSectionSliceProps
}) {
  const darkmode = useDarkModeState()
  const handleClickButton = (link: string | null) => {
    if (link) {
      window.location.href = link
    }
  }
  const ContainerStyle = css({
    backgroundColor:
      (!darkmode
        ? slice.primary.button_section_background_color_light
        : slice.primary.button_section_background_color_dark) ||
      vars.$semantic.color.paperBackground,
  })()
  return (
    <Container className={ContainerStyle}>
      <DescriptionSection>
        <PrismicRichTextComponent
          field={slice.primary.button_section_description}
        />
      </DescriptionSection>
      <ButtonComponent
        text={slice.primary.button_section_button_text}
        onClick={handleClickButton.bind(
          null,
          slice.primary.button_section_button_link.url,
        )}
      />
    </Container>
  )
}

const Container = styled('section', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: 40,
  justifyContent: 'center',
  alignItems: 'center',
})
