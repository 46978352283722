import React, { Fragment } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import bridge from '../../../bridge'
import LazyImageContainer from './commons/LazyImage'
import createUrlPath from '../utils/createUrlPath'

import type { SliceItemType, ImageProps } from '../types'

export interface ImageSectionProps
  extends SliceItemType<'image_section', string | null> {
  primary: ImagePrimaryProps
  items: ImageItemProps
}

type ImageItemProps = any

type ImagePrimaryProps = {
  full_image: ImageProps
  full_image_link: string | null
  full_image_web_modal_text: string | null
  full_image_web_link: string | null
}

export default function ImageSection({ slice }: { slice: ImageSectionProps }) {
  const item = slice.primary
  const image = getImage(item.full_image.localFile)
  const handleClickImage = () => {
    if (item.full_image_link) {
      const result = createUrlPath(item.full_image_link)
      if (result?.wv === 'remote') {
        bridge?.bridgeInstance.pushRouter({
          router: {
            remote: result.remote as string,
            navbar: true,
            scrollable: true,
            enableSafeAreaInsets: true,
          },
        })
      }
      if (result?.wv === 'local') {
        bridge?.bridgeInstance.pushRouter({
          router: {
            app: result.app as string,
            path: result.path as string,
            navbar: true,
            scrollable: true,
            enableSafeAreaInsets: true,
          },
        })
      }
    }
  }
  if (image) {
    return (
      <Fragment>
        <LazyImageContainer onClick={handleClickImage}>
          <GatsbyImage
            style={{ width: '100%' }}
            image={image}
            alt={item.full_image.alt || ''}
          />
        </LazyImageContainer>
      </Fragment>
    )
  }
  return null
}
