import React, { useMemo } from 'react'
import { styled, css } from '@stitches/react'
import Slider from 'react-slick'
import { vars } from '@seed-design/design-token'

import SlideItem from './commons/SlideItem'
import LeftArrow from '../assets/LeftArrow'
import RightArrow from '../assets/RightArrow'

import PrismicRichTextComponent from './commons/PrismicRichTextComponent'
import DescriptionSection from './commons/DescriptionSection'

import type {
  ImageProps,
  PrismicRichTextComponentProps,
  SliceItemType,
  TextProps,
} from '../types'

export interface CardCarouselSliceProps
  extends SliceItemType<'cards_carousel', string | null> {
  items: CardCarouselSliceItemProps[]
  primary: CardCarouselSlicePrimaryProps
}

export type CardCarouselSliceItemProps = {
  image: ImageProps
  additional_info: TextProps[]
}

type CardCarouselSlicePrimaryProps = {
  cards_carousel_description: PrismicRichTextComponentProps
  cards_carousel_background_color_light: string | null
  cards_carousel_background_color_dark: string | null
}

function NextArrow(props: any) {
  const { className, style, onClick } = props
  return <RightArrow className={className} onClick={onClick} {...style} />
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props
  return <LeftArrow className={className} onClick={onClick} {...style} />
}

export default function CardsCarousel({
  slice,
}: {
  slice: CardCarouselSliceProps
}) {
  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      draggable: true,
      speed: 350,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      appendDots: (dots: any) => (
        <div
          style={{
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          <SliderDots style={{ margin: '0px' }}> {dots} </SliderDots>
        </div>
      ),
    }),
    [],
  )
  const ContainerStyle = css({
    backgroundColor:
      slice.primary.cards_carousel_background_color_light || 'none',
  })()

  return (
    <Container className={ContainerStyle}>
      <DescriptionSection>
        <PrismicRichTextComponent
          {...slice.primary.cards_carousel_description}
        />
      </DescriptionSection>
      <Slider {...settings}>
        {slice.items.map((item) => (
          <SlideItem {...item} />
        ))}
      </Slider>
    </Container>
  )
}

const Container = styled('section', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: 40,
  '& svg > path': {
    fill: vars.$scale.color.gray900,
  },
  '& .slick-disabled': {
    cursor: 'not-allowed',
    '& > path': {
      fill: vars.$scale.color.gray200,
      stroke: vars.$scale.color.gray200,
    },
  },
  '& .slick-list': {
    height: 300,
    '& div': {
      height: '100%',
    },
    '& > div > div > div > div > div': {
      paddingBottom: 0,
    },
    '& img': {
      height: '100%',
      paddingBottom: 0,
      width: '100%',
      objectFit: 'contain !important',
    },
  },
})

const SliderDots = styled('ul', {
  '& .slick-active > button::before': {
    color: vars.$scale.color.gray900 + '!important',
  },
  '& li > button::before': {
    color: vars.$scale.color.gray700 + '!important',
  },
})
