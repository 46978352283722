import React, { useEffect } from 'react'
import bridge from '../bridge'

export default function useAddPVCount(
  documentID?: string,
  queryParams?: Record<string, any>,
) {
  const country = process.env.REACT_APP_COUNTRY_CODE
  useEffect(() => {
    if (!documentID) {
      return
    }
    if (typeof window === 'undefined') return
    try {
      bridge?.logAnalyticsEvent('show_event_page', {
        ...queryParams,
        country: country,
        post_id: documentID,
      })
    } catch (error) {
      const e = error as any
      // captureException(error)
      if (e.response && e.response.status === 404) {
        window.location.replace(`/404?country=${country}`)
      } else {
        // TODO: redirect another page
        window.location.replace(`/404?country=${country}`)
      }
    }
  }, [])
  return
}
