import React from 'react'
import { styled } from '@stitches/react'
import { vars } from '@seed-design/design-token'

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  text?: string | null
}

export const ButtonComponent: React.FC<ButtonProps> = ({ text, ...props }) => {
  return <Button {...props}>{text || ''}</Button>
}

const Button = styled('button', {
  borderRadius: 100,
  padding: 10,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: vars.$scale.color.carrot500,
  width: '27rem',
  minWidth: 300,
  height: '10rem',
  border: 'none',
  color: vars.$static.color.staticWhite,
  fontWeight: 'bold',
  fontSize: '3rem',
  cursor: 'pointer',
  marginTop: '5%',
  marginBottom: '5%',
})

export default ButtonComponent
