export default function createUrlPath(url: string) {
  const urlObject = new URL(url)
  const protocol = urlObject.protocol
  const isKarrotProtocol =
    protocol === 'karrot:' || protocol === 'karrot.alpha:'
  const searchParams = []
  // ??
  for (let i of urlObject.searchParams) {
    searchParams.push(i)
  }
  const isRemoteWebView = searchParams.filter((param) => param[0] === 'remote')
  const isLocalWebView = searchParams.filter((param) => param[0] === 'app')
  const pathParamOfLocalWebView = isLocalWebView.length
    ? searchParams.filter((param) => param[0] === 'path')[0][1]
    : undefined
  if (!isKarrotProtocol) {
    return {
      wv: 'remote',
      remote: urlObject.href,
    }
  }
  if (isRemoteWebView.length) {
    return {
      wv: 'remote',
      remote: isRemoteWebView[0][1],
    }
  }
  if (isLocalWebView.length) {
    return {
      wv: 'local',
      app: isLocalWebView[0][1],
      path: pathParamOfLocalWebView,
    }
  }
}
