import React, { Fragment } from 'react'
import { useLocation } from '@reach/router'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import bridge from '../../../bridge'
import LazyImageContainer from './commons/LazyImage'

import type { SliceItemType, ImageProps } from '../types'
import { createButtonClickLog } from '../utils/createButtonClickLog'

export interface ShareButtonSectionProps
  extends SliceItemType<'share_button', string | null> {
  primary: ShareButtonPrimaryProps
  items: ShareButtonItemProps
}

type ShareButtonItemProps = any

type ShareButtonPrimaryProps = {
  share_button_image_id: string | null
  share_button_share_text: string | null
  share_button_share_link: string | null
  share_button_web_link: string | null
  share_button_web_modal_text: string | null
  share_button_image: ImageProps
}

export default function ShareButtonSection({
  slice,
}: {
  slice: ShareButtonSectionProps
}) {
  const item = slice.primary
  const location = useLocation()
  const postUID = location.pathname.split('/')[2]
  const image = getImage(item.share_button_image.localFile)

  const handleClickShareButton = (
    shareUrl: string | null,
    shareText: string | null,
  ) => {
    try {
      if (shareUrl) {
        bridge?.bridgeInstance.share({
          share: {
            text: shareText || '당근마켓 공유하기',
            url: shareUrl,
          },
        })
        if (item.share_button_image_id) {
          createButtonClickLog(
            {
              post_id: postUID,
              button_id: item.share_button_image_id,
              country: (process.env.COUNTRY_CODE || 'kr') as CountryCode,
            },
            'share-btn',
          )
        }
      }
    } catch (err) {
      alert(
        '공유 버튼을 활성화 하는 중 문제가 생겼어요. 불편을 드려 죄송해요. \n 앱의 버전 문제이거나, 기기의 버전 문제일 수 있어요.',
      )
    }
  }

  return (
    <Fragment>
      <LazyImageContainer
        onClick={() =>
          handleClickShareButton(
            item.share_button_share_link,
            item.share_button_share_text,
          )
        }
      >
        {image && (
          <GatsbyImage
            style={{ width: '100%' }}
            image={image}
            alt={item.share_button_image.alt || ''}
          />
        )}
      </LazyImageContainer>
    </Fragment>
  )
}
