import {
  installKarrotBridgeDriver,
  makeKarrotBridge,
} from '@daangn/karrotbridge'

type Event = {
  target: 'FIREBASE' | 'KARROT'
  name: string
  params?: {
    [key: string]: string | number | boolean | null | undefined
  }
}

export const karrotBridge = (() => {
  if (typeof window === 'undefined') {
    return null
  }

  const { driver } = installKarrotBridgeDriver()
  const bridgeInstance = makeKarrotBridge({ driver })

  const logAnalyticsEvent = (name: string, params?: Event['params']): void => {
    const event: Omit<Event, 'params'> & { params: string } = {
      target: 'FIREBASE',
      name,
      params: JSON.stringify(params ? { ...params } : {}),
    }
    if (typeof window === 'undefined') {
      return
    }
    bridgeInstance.logEvent({ analytics: event })
    return
  }

  return { bridgeInstance, logAnalyticsEvent }
})()

export default karrotBridge
