import React, { useMemo } from 'react'
import { styled } from '@stitches/react'
import { useLocation } from '@reach/router'
import { vars } from '@seed-design/design-token'
import { css } from 'gatsby-theme-stitches/src/config'

import useDarkModeState from '../../../hooks/useDarkModeState'

import type { SliceItemType, TextProps } from '../types'
import { handleLoggingButtonClick } from '../utils/createButtonClickLog'

const { $scale, $static } = vars

export interface FloatingButtonSliceProps
  extends SliceItemType<'floating_button', string | null> {
  items: any[]
  primary: FloatingButtonPrimaryProps
}

type FloatingButtonPrimaryProps = {
  floating_button_background_color_light?: string
  floating_button_background_color_dark?: string
  floating_button_border_radius?: number
  floating_button_button_text?: { richText: TextProps[] }
  floating_button_bottom_position?: number
  floating_button_button_width?: number
  floating_button_button_height?: number
  floating_button_button_link?: string
  floating_button_button_link_web?: string
  floating_button_button_logging_id?: string | null
  floating_button_web_modal_text?: {
    richText: TextProps[]
  }
  floating_button_shadow_effect?: boolean
}

export default function FloatingButtonSlice({
  slice,
}: {
  slice: FloatingButtonSliceProps
}) {
  const location = useLocation()
  const postUID = location.pathname.split('/')[2]
  const mode = useDarkModeState()
  const backgroundColor = useMemo(
    () =>
      (function () {
        if (!mode) {
          return (
            slice.primary.floating_button_background_color_light ||
            $scale.color.carrot500
          )
        }
        if (mode) {
          return (
            slice.primary.floating_button_background_color_dark ||
            $scale.color.carrot500
          )
        }
      })(),
    [mode],
  )

  const htmlTagReducer = (function () {
    if (!slice.primary.floating_button_button_text) return 'div'
    switch (slice.primary.floating_button_button_text.richText[0].type) {
      case 'heading1':
        return 'h1'
      case 'heading2':
        return 'h2'
      case 'heading3':
        return 'h3'
      case 'heading4':
        return 'h4'
      case 'heading5':
        return 'h5'
      case 'heading6':
        return 'h6'
      default:
        return 'div'
    }
  })()

  const buttonStyle = css({
    backgroundColor: backgroundColor,
    borderRadius: slice.primary.floating_button_border_radius || 5,
    borderBottomLeftRadius: !(
      slice.primary.floating_button_bottom_position || 0
    )
      ? 0
      : slice.primary.floating_button_border_radius || 5,
    borderBottomRightRadius: !(
      slice.primary.floating_button_bottom_position || 0
    )
      ? 0
      : slice.primary.floating_button_border_radius || 5,
    bottom: slice.primary.floating_button_bottom_position || 0,
    width: `${slice.primary.floating_button_button_width || 100}%`,
    boxShadow: slice.primary.floating_button_shadow_effect
      ? `0px 6px 15px rgba(255, 126, 54, 0.2)`
      : 'none',
  })()

  const FloatingButtonWrapper = useMemo(
    () =>
      styled(htmlTagReducer, {
        position: 'fixed',
        height: slice.primary.floating_button_button_height || 50,
        left: 0,
        margin: '0 auto',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: $static.color.staticWhite,
        cursor: 'pointer',
      }),
    [mode],
  )

  return (
    <FloatingButtonWrapper
      className={buttonStyle}
      onClick={() =>
        handleLoggingButtonClick(
          postUID,
          slice.primary.floating_button_button_logging_id || null,
          slice.primary.floating_button_button_link || null,
          'floating-button',
        )
      }
    >
      {slice.primary.floating_button_button_text &&
        slice.primary.floating_button_button_text.richText[0].text}
    </FloatingButtonWrapper>
  )
}
