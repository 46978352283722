import React, { ImgHTMLAttributes } from 'react'
import { styled } from 'gatsby-theme-stitches/src/config'

export const LazyImageContainer: React.FC<
  ImgHTMLAttributes<HTMLImageElement>
> = ({ children, ...props }) => {
  return <ImageContainer {...props}>{children}</ImageContainer>
}

const ImageContainer = styled('div', {
  width: '100%',
  minWidth: '100%',
  '& .lazy-img': {
    width: '100%',
    filter: 'blur(15px)',
    minHeight: 500,
    objectFit: 'fill',
  },
  '& .loaded': {
    width: '100%',
    minHeight: 'auto',
    filter: 'none',
  },
})

export default LazyImageContainer
