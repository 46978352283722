import React, { useState, Fragment } from 'react'
import { styled, css } from '@stitches/react'
import { vars } from '@seed-design/design-token'
import { getImage } from 'gatsby-plugin-image'

import useDarkModeState from '../../../hooks/useDarkModeState'

import { ImageProps, SliceItemType, TextProps } from '../types'
import DropDownSVG from '../assets/Dropdown'
import {
  smoothDownAnimation,
  smoothUpAnimation,
} from '../animations/smoothDown'
import PrismicRichTextComponent from './commons/PrismicRichTextComponent'

import type { PrismicRichTextComponentProps } from '../types'
import { GatsbyImage } from 'gatsby-plugin-image'

const { $scale, $static } = vars

export interface FaqProps extends SliceItemType<'faq_section', string | null> {
  items: FaqItemProps[]
  primary: FaqPrimaryProps
}

type FaqPrimaryProps = {
  optional_image: ImageProps
  faq_description: PrismicRichTextComponentProps
  faq_background_color_light: string | null
  faq_background_color_dark: string | null
}

type FaqItemProps = {
  title: TextProps
  text: TextProps
}

export default function FaqSlice({ slice }: { slice: FaqProps }) {
  const image = getImage(slice.primary.optional_image.localFile)
  const ContainerStyle = css({
    backgroundColor: slice.primary.faq_background_color_light || 'none',
  })()
  return (
    <Container className={ContainerStyle}>
      <InfoContainer>
        <PrismicRichTextComponent {...slice.primary.faq_description} />
      </InfoContainer>
      <FAQContainer>
        {image && (
          <LeftSection>
            <GatsbyImage
              image={image}
              alt={slice.primary.optional_image.alt || ''}
            />
          </LeftSection>
        )}
        <RightSection>
          {slice.items.map((s, idx) => {
            return (
              <FAQItemComponent key={s.title?.text} data={s}></FAQItemComponent>
            )
          })}
        </RightSection>
      </FAQContainer>
    </Container>
  )
}

const FAQItemComponent: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { data: FaqItemProps }
> = ({ children, data, ...props }) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Fragment>
      <FAQItem {...props} active={open} onClick={setOpen.bind(null, !open)}>
        <FAQItemTitleWrapper>
          <p className="title"> {data.title?.text} </p>
          <DropDownSVG />
        </FAQItemTitleWrapper>
        <FAQItemDescriptionWrapper className={open ? 'active' : 'inactive'}>
          {data.text?.text}
        </FAQItemDescriptionWrapper>
      </FAQItem>
    </Fragment>
  )
}

const Container = styled('section', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 20px',
})

const InfoContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 30,
  color: $static.color.staticBlack,
})

const FAQContainer = styled('section', {
  display: 'flex',
  flexDirection: 'row',
})

const LeftSection = styled('div', {
  width: '100%',
  display: 'flex',
  marginRight: 15,
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
})

const RightSection = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const FAQItem = styled('div', {
  width: '100%',
  padding: '1rem',
  borderBottom: `1px solid ${$scale.color.gray200}`,
  cursor: 'pointer',
  transition: 'all ease 0.5s',
  color: vars.$scale.color.gray900,
  '& svg': {
    transition: 'all ease 0.5s',
  },
  '& svg path': {
    fill: $scale.color.gray900,
  },
  '& .active': {
    animation: `${smoothDownAnimation} .3s`,
    maxHeight: 100,
    overflow: 'scroll',
  },
  '& .inactive': {
    animation: `${smoothUpAnimation} .3s`,
    overflow: 'hidden',
    maxHeight: 0,
  },
  variants: {
    active: {
      true: {
        '& svg': {
          transform: 'rotate(180deg) scale(0.7)',
        },
      },
      false: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
  },
})

const FAQItemTitleWrapper = styled('h2', {
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'space-between',
  '& > p': {
    fontSize: '2rem',
    lineHeight: 1.35,
  },
})

const FAQItemDescriptionWrapper = styled('h4', {
  fontWeight: 'normal',
  fontSize: '1.5rem',
})
