import React from "react";
import { styled } from "@stitches/react";

import type { SliceItemType } from "../types";

export interface EmbedSectionProps
  extends SliceItemType<"embed_content", string | null> {
  items: EmbedSectionItemProps[];
  primary: {
    embeded_item: EmbedSectionPrimaryProps;
  };
}

interface EmbedSectionPrimaryProps {
  provider_name: string;
  provider_url: string;
  title: string;
  author_name: string;
  author_url: string;
  type: "video";
  height: number;
  width: number;
  version: string;
  thumbnail_height: number;
  thumbnail_width: number;
  thumbnail_url: string;
  html: string;
  embed_url: string;
}

interface EmbedSectionItemProps {}

export default function EmbedSection({ slice }: { slice: EmbedSectionProps }) {
  const slicePrimary = slice.primary;
  return (
    <VideoWRapper
      dangerouslySetInnerHTML={{ __html: slicePrimary.embeded_item.html }}
    ></VideoWRapper>
  );
}

const VideoWRapper = styled("div", {
  width: "100%",
  paddingBottom: "56%",
  position: "relative",
  "& > iframe": {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
});
