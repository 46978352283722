import React from 'react'
import { styled } from '@stitches/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import PrismicRichTextComponent from './PrismicRichTextComponent'

import type { CardCarouselSliceItemProps } from '../CardsCarouselSection'
import DescriptionSection from './DescriptionSection'

const SlideItem: React.FC<
  CardCarouselSliceItemProps &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>
> = ({ image, ...props }) => {
  const imageItem = getImage(image.localFile)
  return (
    <Container {...props}>
      {image && (
        <ImageContainer>
          {imageItem && (
            <GatsbyImage
              image={imageItem}
              alt={image.alt || ''}
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </ImageContainer>
      )}
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const ImageContainer = styled('div', {
  width: '100%',
  paddingBottom: '100%',
  position: 'relative',
})

const Image = styled('img', {
  padding: '1rem',
  top: 0,
  left: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
})

export default SlideItem
