import bridge from '../../../bridge'
import createUrlPath from './createUrlPath'

export type ButtonLoggingType = {
  post_id: string
  country: CountryCode
  button_id: string | null
}

export const createButtonClickLog = (
  params: ButtonLoggingType,
  type: string,
) => {
  if (params.button_id) {
    bridge?.logAnalyticsEvent('click_event_page_normal_button', {
      ...params,
      type,
    })
  }
}

export const handleLoggingButtonClick = (
  postUId: string,
  buttonId: string | null,
  link: string | null,
  type: string,
) => {
  if (link) {
    if (buttonId) {
      // logging
      createButtonClickLog(
        {
          post_id: postUId,
          button_id: buttonId,
          country: (process.env.COUNTRY_CODE || 'kr') as CountryCode,
        },
        type,
      )
    }

    // routing
    const result = createUrlPath(link)
    if (result?.wv === 'remote') {
      bridge?.bridgeInstance.pushRouter({
        router: {
          remote: result.remote as string,
          navbar: true,
          scrollable: true,
          enableSafeAreaInsets: true,
        },
      })
    }
    if (result?.wv === 'local') {
      bridge?.bridgeInstance.pushRouter({
        router: {
          app: result.app as string,
          path: result.path as string,
          navbar: true,
          scrollable: true,
          enableSafeAreaInsets: true,
        },
      })
    }
  }
}
