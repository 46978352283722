import React, { Fragment } from 'react'
import { useLocation } from '@reach/router'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { handleLoggingButtonClick } from '../utils/createButtonClickLog'
import LazyImageContainer from './commons/LazyImage'

import type { SliceItemType, ImageProps } from '../types'

export interface LoggingButtonSectionProps
  extends SliceItemType<'logging_button', string | null> {
  primary: LoggingButtonPrimaryProps
  items: LoggingButtonItemProps
}

type LoggingButtonItemProps = any

type LoggingButtonPrimaryProps = {
  logging_button_image_id: string | null
  logging_button_image_link: string | null
  logging_button_web_modal_text: string | null
  logging_button_web_link: string | null
  logging_button_image: ImageProps
}

export default function LoggingButtonSection({
  slice,
}: {
  slice: LoggingButtonSectionProps
}) {
  const item = slice.primary
  const location = useLocation()
  const postUID = location.pathname.split('/')[2]
  const image = getImage(item.logging_button_image.localFile)

  return (
    <Fragment>
      <LazyImageContainer
        onClick={() =>
          handleLoggingButtonClick(
            postUID,
            item.logging_button_image_id,
            item.logging_button_image_link,
            'normal-btn',
          )
        }
      >
        {image && (
          <GatsbyImage
            style={{ width: '100%' }}
            image={image}
            alt={item.logging_button_image.alt || ''}
          />
        )}
      </LazyImageContainer>
    </Fragment>
  )
}
