import React from 'react'
import { styled } from '@stitches/react'
import { PrismicRichText, PrismicRichTextProps } from '@prismicio/react'

import type { PrismicRichTextComponentProps } from '../../types'

export const PrismicRichTextComponent: React.FC<
  PrismicRichTextComponentProps
> = (props) => {
  if (!props.richText) {
    return null
  }
  return (
    <PrismicRichText
      field={props.richText as any}
      components={{
        heading1: ({ children }) => (
          <PrismicHeading1> {children} </PrismicHeading1>
        ),
        heading2: ({ children }) => (
          <PrismicHeading2> {children} </PrismicHeading2>
        ),
        heading3: ({ children }) => (
          <PrismicHeading3> {children} </PrismicHeading3>
        ),
        heading4: ({ children }) => (
          <PrismicHeading4> {children} </PrismicHeading4>
        ),
        heading5: ({ children }) => (
          <PrismicHeading5> {children} </PrismicHeading5>
        ),
        heading6: ({ children }) => (
          <PrismicHeading6> {children} </PrismicHeading6>
        ),
        paragraph: ({ children }) => <p>{children}</p>,
      }}
    />
  )
}

const PrismicHeading1 = styled('h1', {
  fontSize: '4.5rem',
  lineHeight: '134%',
  letterSpacing: 0,
})

const PrismicHeading2 = styled('h1', {
  fontSize: '3.375rem',
  lineHeight: '140%',
  letterSpacing: 0,
})

const PrismicHeading3 = styled('h1', {
  fontSize: '2.625rem',
  lineHeight: '142%',
  letterSpacing: 0,
})

const PrismicHeading4 = styled('h1', {
  fontSize: '2rem',
  lineHeight: '150%',
  letterSpacing: 0,
})

const PrismicHeading5 = styled('h1', {
  fontSize: '1.625rem',
  lineHeight: '138%',
  letterSpacing: 0,
})

const PrismicHeading6 = styled('h1', {
  fontSize: '1.5rem',
  lineHeight: '130%',
  letterSpacing: 0,
})

export default PrismicRichTextComponent
